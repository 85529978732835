<template>
  <div class="box-wrapper" v-loading="loading" 
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <div class="box-title">
        <span>{{ datas.graphName }}</span>
        <div class="title-badge">
          <span class="badge" v-if="datas.statisticsDimension=='realtime'" :style="datas.statisticsDimension=='realtime'&&'background-color:#27ae60;'">实时</span>
        </div>
      </div>
      <div id="distributionrealtime1" class="distribution-realtime" :style="'height:'+height+'px;'"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
let startTime = new Date(new Date().toLocaleDateString()).getTime(); // 当天0点
let endTime = new Date(new Date().toLocaleDateString()).getTime() +24 * 60 * 60 * 1000;// 当天23:59
export default {
  props: {
    datas: {
      type: Object
    },
    graphDisFirstData: {
      type: Array
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      height: 600,
      stepTime: null,
      currentTime: new Date(),
      intervalTime: 0,
      reportData: [],
      graphData: [],
      myChart: null
    };
  },
  watch: {
    datas: {
      handler (newVal, oldVal) {
        this.datas = newVal
        this.data = this.datas.data
        this.cycleData()
        let that = this
        that.setSize()
        window.addEventListener('resize', function() {
          that.setSize()
        }, false);
        newVal && newVal.length!=0 && this.setChart()
      }
    },
    graphDisFirstData: {
      handler (newVal, oldVal) {
        this.graphData = newVal
        newVal && newVal.length!=0 && this.setChart()
      }
    }
  },
  methods: {
    dayjs,
    // 封装指定时间格式
    getCurrentTime(time) {
      let date = new Date(time)
      let year = date.getFullYear()
      let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    // 追加实时数据
    cycleData() {
      let date = new Date().getTime()
      // n个设备
      this.graphData.forEach(item => {
        // 推送的n个设备对应数据
        this.datas.data.forEach(v=>{
          if(item.name == (v.eid+'['+v.tag+'-'+v.describe+']')) {
            item.data.push({
              name: date,
              value: [date, parseFloat(v.value).toFixed(2)]
            })
          }
        })
      })
    },
    setChart() {
      this.myChart = echarts.init(document.getElementById("distributionrealtime1"));
      var option = {
        title: {
          subtext: '-',
          top: -8,
          left: 18
        },
        backgroundColor: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'time',
            boundaryGap: false,
            animationDuration: 300,
            animationDurationUpdate: 300,
            splitLine: {
              show: false
            },
            splitNumber:8,
            axisLine:{
              lineStyle:{
                color: '#1B2232'
              }
            },
            min: this.getCurrentTime(startTime),
            max: this.getCurrentTime(endTime),
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.graphData,
        legend: {
          show: true
        },
        animationDuration: 3000,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };

      option.title.subtext = '单位: '+(this.datas?this.datas.unitAttribute:'')
      // option.series[0].name = this.datas?this.datas.elemental1Attribute:''
      this.myChart.setOption(option);
      setTimeout(()=>{
        window.onresize = function () {
          this.myChart.resize();
        }
        window.addEventListener('keydown', function(event) {
          if(event.keyCode===122) {
            this.myChart.resize();
          }
        })
      },0)
      console.log('折线图执行')
    },
    setSize() {
      let elMain = window.innerHeight
      let rBox = document.querySelector('.box-wrapper')
      let rMain = document.querySelector('.distribution-realtime')
      if(rBox) rBox.style.height = elMain - 212 + 'px'
      if(rMain) rMain.style.height = this.height + 'px'
    },
  },
  created() {
    // this.height = this.datas.data.length * 100
  },
  mounted() {
    // this.data = this.datas.data
    // console.log(this.data)
    // this.setChart()
    // let that = this
    // that.setSize()
    // window.addEventListener('resize', function() {
    //   that.setSize()
    // }, false);
    
  },
  destroyed() {
    clearInterval(this.foldLineTimer)
  }
};
</script>

<style lang="less" scoped>
.distribution-realtime {
  width: 100%;
  height: 770px;
  margin: 20px auto 0;
  z-index: 2;
}
.box-title {
  // color: #FFF;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  top: 6px;
  left: 6px;
  z-index: 1;
  .title-badge {
    margin-left: 20px;
    .badge {
      background: #409EFF;
      color: #FFF;
      padding: 2px 10px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 50px;
      font-weight: normal;
    }
  }
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 40px;
}
.box-wrapper {
  overflow: hidden;
  // background-color: #04102c;
  position: relative;
  // border-radius: 10px;
  // height: 831px;
  // margin-bottom: 10px;
  .chart-title {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 18px auto 0;
    z-index: 0;
  }
  .chart-bg {
    width: 98%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
  .content {
    border-radius: 10px;
    padding-bottom: 16px;
  }
  .date {
    display: flex;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 10;
    .date-text {
      font-size: 12px;
      font-weight: normal;
      color: #FFF;//#767779
      margin-right: 10px;
      user-select: none;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
/deep/.el-form-item__content {
  line-height: 50px;
}
/deep/.el-form-item__label {
  font-size: 12px;
  font-weight: normal;
  color: #FFF;//#767779
}
</style>